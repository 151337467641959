.store {
  padding: 120px 0 120px;
  min-height: 100vh;
  min-height: 100svh;
  .store_wrap {
    width: "100%";
    position: relative;
  }
}

.subtxt {
  span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px !important;
    letter-spacing: 1px;
    max-width: 320px;
    margin-top: 6px;
    display: block;
    b {
      transition: all 0.3s ease;
      &:hover {
        color: rgb(0, 0, 0);
      }
    }
  }
}

.wallet_card {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: fit-content;
  margin-bottom: 10px;
  padding: 6px 12px;
  div {
    font-family: 'Inter';
    &:nth-child(1) {
      span {
        display: block;
        &:nth-child(1) {
          font-weight: 400;
          font-size: 11px;
          color: rgba(0, 0, 0, 0.3);
          margin-bottom: 2px;
        }
        &:nth-child(2) {
          font-weight: 700;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
        }
        &:nth-child(3) {
          font-weight: 400;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    &:nth-child(2) {
      margin-left: 24px;
      color: rgba(0, 0, 0, 0.2);
    }
  }
}

.ord_review {
  padding: 16px;
  background: #fff;
  width: 100%;
  border-radius: 12px;
  margin-top: 16px;
  h3 {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-bottom: 0px;
  }
  p {
    margin: 0px 0 0;
    color: rgb(0, 0, 0);
    font-size: 16px !important;
    font-family: 'Inter';
  }
}

.open_link {
  font-size: 13px;
  opacity: 0.6;
  margin-top: 6px;
  max-width: 200px;
  a {
    color: #fff !important;
    text-decoration: none !important;
    border-bottom: 1px solid #fff !important;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  .subtxt {
    span {
      font-size: 12px !important;
      // margin-top: 8px !important;
    }
  }
}

.txtt1 {
  color: rgba(0, 0, 0, 0.9);
  font-family: 'RobotoCondensed';
  font-size: 20px;
  margin-bottom: 28px;
  margin-top: -12px;
  transition: all 0.4s ease;
}

.store_body {
  height: 100%;
  position: relative;
  padding-right: 35px;
  &.afterVisible {
    &::after {
      top: 85px !important;
      opacity: 1 !important;
    }
  }
  // &::after {
  //   content: " ";
  //   width: 1px;
  //   height: 100%;
  //   position: absolute;
  //   top: 70px;
  //   left: -35px;
  //   opacity: 0;
  //   transition: all 0.4s ease;
  //   background-color: rgba(255, 255, 255, 0.1);
  // }
  // &::after {
  //   left: unset;
  //   right: 6px;
  // }
  .store_body__ttl {
    color: #fff;
    font-size: 48px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 12px;
    transition: all 0.4s ease;
    position: relative;
  }
}

.page_blink {
  background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%);
  width: 1688px;
  height: 1688px;
  position: absolute;
  left: -944px;
  margin: auto;
  transition: all 1s ease;
  animation: rotatta 12s infinite linear;
  opacity: 0;

  &.blue {
    background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%);
    animation: rotatta2 12s infinite linear;
  }
  &.right {
    left: unset !important;
    right: -944px !important;
    transition: all 1.5s ease;
  }
  &.toped {
    margin-top: -600px;
  }
  &.toped2 {
    margin-top: -800px;
  }
  &.toped22 {
    margin-top: -400px;
  }
  &.toped4 {
    margin-top: -1200px;
  }
  &.toped5 {
    margin-top: -1600px;
  }
  &.toped3 {
    margin-top: -50px;
  }
}

@keyframes rotatta {
  0% { 
    transform: rotate(0deg) translateY(100px) rotate(0deg); 
  } 
  100% { 
    transform: rotate(360deg) translateY(100px) rotate(-360deg); 
  } 
}

@keyframes rotatta2 {
  0% { 
    transform: rotate(0deg) translateY(100px) rotate(0deg); 
  } 
  100% { 
    transform: rotate(-360deg) translateY(100px) rotate(360deg); 
  } 
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.page_blink {
  //background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%) !important;
  background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
  &.blue {
    //background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
    top: 260px;
  }
  &.reddz {
   // background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
    background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%) !important;
  }
  &.toped {
    margin-top: -550px !important;
  }
}

@media screen and (max-width: 768px) {
  .store {
    padding-top: 72px !important;
    .store_body__ttl {
      font-size: 36px !important;
      margin-bottom: 6px !important;
    }
    .store_body {
      padding-right: 0px !important;
    }
  }
  .orders_list {
    flex-direction: column !important;
    width: 100% !important;
    .ol_item {
      width: 100% !important;

      .ol_item__img {
        position: absolute !important;
        // top: 100px !important;
        top: 12px !important;
        right: 0px;
      }
      .ol_item__data {
        width: 100% !important;
        min-width: 100% !important;
        padding-right: 0px !important;
        h2 {
          font-size: 17px !important;
          letter-spacing: .2px !important;
          line-height: 1.3 !important;
          max-width: 72%;
        }
      }
    }
  }
  .tabs {
    // flex-wrap: wrap;
    .tabs_item {
      min-width: fit-content;
      font-size: 22px !important;
      margin-right: 20px !important;
      letter-spacing: 1px;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
}


.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 56px;
  padding-top: 26px;
  position: relative;
  z-index: 9999999;
  width: 50%;
  --bg-width: 0%;
  transition: all 0.4s ease;
  &::before {
    content: " ";
    position: absolute;
    top: 0px;
    // width: 100%;
    width: var(--bg-width);
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    transition: all 0.4s ease;
  }
  .tabs_item {
    margin-right: 26px;
    font-size: 32px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all 0.3s ease;
    &:last-child {
      margin-right: 0px !important;
    }
    &.active, &:hover, &:active {
      color: #fff;
    }
  }
}

.lang {
  position: absolute;
  top: -34px;
  transition: all 0.4s ease;
}

@media screen and (max-width: 768px) {
  .lang {
    display: none;
  }

  .status {
    padding: 8px 12px 5px !important;
  }
}

.orders_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .ol_item {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(6px);
    margin-bottom: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    transition: all 0.4s ease;
    padding: 16px;
    position: relative;
    .ol_item__img {
      width: 30%;
      min-width: 30%;
      padding-right: 20px;
      position: absolute;
      top: 10px;
      img {
        width: 110%;
        // position: absolute;
        // right: 0;
      }
    }
    .ol_item__data {
      width: 100%;
      // padding-right: 20px;
      h2 {
        color: rgb(0, 0, 0);
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.2px;
        font-family: 'Inter';
        display: block;
        border: none;
        padding: 4px 0 12px;
        max-width: 72%;
      }

      .address {
        max-width: 360px;
        .address_lbl {
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
          font-family: 'RobotoCondensed';
          margin-bottom: 6px;
        }
        .address_fld {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 46px;
          position: relative;
          background-color: rgba(0, 0, 0, 0.07);
          border-radius: 7px;
          padding: 0 12px;
          cursor: pointer;
          div {
            transition: all 0.4s ease;
            position: absolute;
            color: rgb(0, 0, 0);
            font-size: 20px;
            font-family: 'RobotoCondensed';
            input {
              background-color: transparent;
              border: none;
              outline: none;
              padding: 0;
              color: rgb(0, 0, 0);
              width: 200%;
              &::placeholder {
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
          .edt {
            color: rgba(0, 0, 0, 0.6);
            position: absolute;
            right: 16px;
            font-size: 20px;
            cursor: pointer;
            transition: all 0.4s ease;
            &:hover {
              color: rgb(0, 0, 0);
            }
          }
        }
      }

      .xlbutton {
        background-color: #fff;
        border: none;
        border-radius: 8px;
        width: 100%;
        display: block;
        padding: 12px 0;
        font-size: 20px;
        font-weight: 500;
        margin-top: 16px;
        position: relative;
        &.loading {
          span:not(.ldrx) {
            &:nth-child(1) {position: absolute;opacity: 0;}
            &:nth-child(2) {opacity: 1;position: relative;}
            &:nth-child(3) {opacity: 0;position: absolute;}
          }
        }
        &.done {
          span {
            &:nth-child(1) {position: absolute;opacity: 0;}
            &:nth-child(2) {opacity: 0;position: absolute;}
            &:nth-child(3) {opacity: 1 !important;position: relative !important;}
          }
        }
        span:not(.ldrx) {
          transition: all 0.4s ease;
          &:nth-child(1) {}
          &:nth-child(2) {opacity: 0;position: absolute;}
          &:nth-child(3) {opacity: 0;position: absolute;}
          b {
            margin-left: 4px;
          }
        }
      }

      .status {
        color: rgba(0, 0, 0, 1);
        background-color: #fff;
        border-radius: 8px;
        font-size: 20px;
        // font-family: 'RobotoCondensed';
        margin-top: 18px;
        width: fit-content;
        line-height: 1;
        padding: 8px 20px 5px;
        letter-spacing: 1.5px;
        &.success {
          background-color: rgb(5, 190, 5);
          color: #fff;
        }
        div {
          &:nth-child(1) {}
          &:nth-child(2) {
            margin-top: 4px;
            font-size: 28px;
            font-weight: 600;
          }
        }
      }

      .sum {
        .red {
          background-color: #e70707;
          color: #fff;
          padding: 2px 12px;
          border-radius: 4px;
          margin-bottom: 8px;
          font-size: 14px;
          font-family: 'Inter';
          width: fit-content;
        }

        .topay {
          color: rgb(0, 0, 0);
          font-family: 'Inter';
          font-size: 18px;
          b {
            font-size: 42px;
            font-family: 'Bebas Neue';
          }
          small {
            margin-left: 4px;
            svg {
              position: relative;
              top: -5px;
              path {
                fill: #000;
              }
            }
          }
          span {
            font-size: 18px;
          }
        }
      }
      
      .pay {
        h4 {
          font-family: 'Inter';
          color: rgba(255, 255, 255, 0.7);
          font-size: 15px;
          font-weight: 400;
        }
        button {
          background: linear-gradient(90deg, #d000a5, #004ddb);
          border: none;
          border-radius: 10px;
          color: #fff;
          font-family: 'Inter';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          height: 52px;
          letter-spacing: .6px;
          line-height: 100%;
          outline: none;
          padding: 16px 0 14px;
          width: 100%;
          max-width: 280px;
          img {
            width: 130px;
          }
        }
        p {
          color: #fff;
          font-size: 15px;
          font-family: 'Inter';
          margin-top: 12px;
        }

        a {
          font-family: 'Inter';
          text-decoration: none;
          line-height: 1;
          color: rgba(0, 0, 0, 0.6);
          display: block;
          font-size: 13px;
          margin-top: 20px;
          position: relative;
          transition: all .4s ease;
          width: fit-content;
          &::before {
            background-color: #fff;
            bottom: -1px;
            content: " ";
            display: block;
            height: 1px;
            position: absolute;
            transition: all .4s ease;
            width: 0;
          }
          &:hover {
            color: #fff;
            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.4s ease;
  .flexed {
    display: flex;
    flex-direction: row;
    .form_field {
      width: 100%;
      margin-right: 16px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .form_field {
    margin-bottom: 20px;
    .form_field__label {
      color: rgb(0, 0, 0);
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 4px;
      letter-spacing: 2px;
    }
    .form_field__input {
      width: 100%;
      input, textarea {
        width: 100%;
        background-color: #00000012;
        border: 2px solid #00000026;
        padding: 12px;
        border-radius: 8px;
        color: rgb(0, 0, 0);
        outline-color: rgb(0, 0, 0);
        font-size: 18px;
        font-family: "RobotoCondensed";
        transition: all 0.2s ease;
        outline-width: 1px;
        &:disabled {
          opacity: 0.6;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active{
          -webkit-background-clip: text;
          -webkit-text-fill-color: #000000;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #23232329;
        }
      }
    }
  }
  .privacy {
    text-align: center;
    margin-top: 44px;
    a {
      color: rgba(0, 0, 0, 0.5);
      transition: all 0.3s ease;
      font-family: "RobotoCondensed";
      font-size: 14px;
      text-decoration: none;
      position: relative;
      &::after {
        content: " ";
        width: 100%;
        background-color: rgba(255, 255, 255, 0.789);
        position: absolute;
        bottom: -4px;
        opacity: 0;
        left: 0px;
        height: 1px;
        transition: all 0.3s ease;
      }
      &:hover {
        color: rgba(255, 255, 255, 0.8);
        &::after {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }
  .form_back {
    text-align: center;
    margin-top: 68px;
    cursor: pointer;
    div {
      // font-family: 'HelveticaNeueCyr';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: 2px;
      color: #CAC9C9;
      img {
        margin-right: 20px;
        width: 28px;
      }
    }
  }
  .form_signwith {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 22px 0 0;
    .form_signwith__ttl {
      border-top: 1px solid #ffffff2e;
      color: #fff9;
      width: 340px;
      padding-bottom: 12px;
      padding-top: 12px;
      text-align: center;
      font-size: 12px;
    }
    #buttonDiv {
      color: #000;
    }
  }
  .form_actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    p {
      color: #b3b3b3;
      font-size: 12px;
      font-weight: 600;
      margin-top: 12px;
    }
    button {
      background-color: #fff;
      color: #000;
      padding: 10px 28px 7px;
      border-radius: 12px;
      border: none;
      display: inline-block;
      margin: 0 auto;
      outline: none;
      font-size: 18px;
      font-weight: 600;
      // line-height: 1;
      letter-spacing: 1.5px;
      min-width: 240px;
      cursor: pointer;
      &.darked {
        background-color: rgba(0, 0, 0, 0.08);
        color: #000;
      }
    }
    a {
      color: rgba(255, 255, 255, 0.5);
      transition: all 0.3s ease;
      font-family: "RobotoCondensed";
      font-size: 14px;
      margin-top: 24px;
      text-decoration: none;
      position: relative;
      &::after {
        content: " ";
        width: 100%;
        background-color: rgba(255, 255, 255, 0.789);
        position: absolute;
        bottom: -4px;
        opacity: 0;
        left: 0px;
        height: 1px;
        transition: all 0.3s ease;
      }
      &:hover {
        color: rgba(255, 255, 255, 0.8);
        &::after {
          opacity: 1;
          bottom: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .flexed {
    flex-direction: column !important;
    width: 100% !important;
  }
  .form {
    margin-top: 0px !important;
  }
}