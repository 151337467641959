.backdrop {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(6px);
  opacity: 0;
  transition: all 0.3s ease;
  &.dark {
    .window {
      background-color: #121212 !important;
      .frame {
        h2 {
          color: #fff !important;
        }
        .closer {
          color: #fff !important;
        }
      }
    }
  }
  &.light {
    .action_zone {
      button {
        b {
          color: rgba(0, 0, 0, 0.6) !important;
        }
      }
    }
  }
  &.hidden {
    display: none;
  }
  &.showed {
    opacity: 1;
  }
  &.openned {
    .window {
      right: 0px !important;
      // opacity: 1 !important;
      &::before {
        opacity: 0.8 !important;
        left: -40px !important;
      }
    }
  }
  .wrapper {
    height: 100vh;
    height: 100svh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .window {
      background-color: #fff;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
      width: 600px;
      height: 100vh;
      height: 100svh;
      max-width: 100%;
      transition: all 0.4s ease;
      position: relative;
      right: -600px;
      opacity: 1;
      // overflow-y: scroll;
      // overflow-x: hidden;
      .frame {
        transition: all 0.3s ease;
        position: relative;
        z-index: 99999999;
        height: 100vh;
        height: 100svh;
      }
      .loading {
        position: absolute;
        top: 120px;
        left: 0;
        width: 100%;
        text-align: center;
        transition: all 0.3s ease;
        font-size: 50px;
        div {
          &:nth-child(2) {
            margin-top: 24px;
            font-size: 20px;
          }
        }
      }
      .closer {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        text-align: center;
        z-index: 99999999;
        transition: all 0.3s ease;
        color: rgba(0, 0, 0, 0.4);
        &:hover {
          color: #000;
        }
      }
      h2 {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.4px;
        display: flex;
        margin-bottom: 0;
        padding: 16px 16px 16px;
        line-height: 1;
        align-items: center;
        transition: all 0.3s ease;
        font-family: 'Inter';
        border-bottom: 1px solid #00000014;
        span {
          font-size: 18px;
          display: inline-flex;
          width: 30px;
          height: 30px;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          transition: all 0.3s ease;
        }
      }
      .action_zone {
        position: absolute;
        width: calc(100% - 32px);
        bottom: 20px;
        left: 16px;
        transition: all 0.3s ease;
        button {
          background: transparent;
          border: none;
          outline: none;
          padding: 1px;
          // margin-right: 16px;
          border-radius: 12px;
          position: relative;
          height: 51px;
          width: 100%;
          overflow: hidden;
          --psop: 0;
          --pslft: 180px;
          &:nth-child(1) {
            span {
              padding: 10px 46px !important;
            }
          }
          .nt {
            position: relative;
            top: -5px;
          }
          span:not(.nt) {
            background: rgba(188, 188, 188, 0.25);
            padding: 10px 76px;
            font-size: 20px;
            display: block;
            font-family: 'RobotoCondensed';
            border-radius: 12px;
            font-weight: 400;
            height: 50px;
            width: 100%;
            color: #fff;
            transition: all 0.3s ease;
            z-index: 999;
            position: relative;
            border: 1px solid #CDCDCD;
            border-style: solid;
            backdrop-filter: blur(4px);
          }
          b {
            position: absolute;
            z-index: 9999999;
            color: #fff;
            font-family: 'RobotoCondensed';
            border-radius: 12px;
            font-weight: 400;
            font-size: 20px;
            top: 11px;
            left: 0px;
            width: 100%;
            text-align: center;
            transition: all 0.3s ease;
          }
          &:nth-child(1) {
            &::after {
              // position: absolute;
              // content: " ";
              // border-radius: 500px;
              // background: radial-gradient(50% 50% at 50% 50%, #2477BD 0%, rgba(0, 0, 0, 0.00) 100%);
              // width: 423px;
              // height: 423px;
              // top: -50px;
              // left: var(--pslft);
              // z-index: 9999;
              // transition: all 6s ease;
            }
          }
          &::before {
            position: absolute;
            content: " ";
            border-radius: 12px;
            top: 0;
            left: 0;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            z-index: 99;
            opacity: 0;
            z-index: 99999;
            transition: all 0.3s ease;
            background: linear-gradient(262deg, #1C5D93 27.77%, #DB0609 95.47%);
          }
          &:disabled {
            pointer-events: none;
            user-select: none;
            opacity: 0.6;
          }
          &:hover {
            &::before {
              opacity: 1;
            }
            span {
              //border: 1px solid transparent;
              //background: transparent;
              border: 1px solid transparent;
            }
            b {
              color: #fff !important;
            }
          }
        }
      }
      .page {
        padding-top: 10px;
        padding-bottom: 100px;
        height: calc(100vh - 62px);
        height: calc(100svh - 62px);
        overflow: auto;
        // overflow-x: hidden;
        padding: 22px 16px 60px;
        .page_content {
          
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .window {
    &::before {
      content: " ";
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0px;
      background: linear-gradient(-5deg, #e51414 0%, #007fe6 100%);
      transform: translate3d(0px, 10px, 0) scale(0.95);
      filter: blur(40px);
      opacity: 0;
      transition: all 0.4s ease;
      border-radius: inherit;
    }
  }
}

@media screen and (max-width: 960px) {
  .action_zone {
    width: calc(100% - 32px) !important;
    // left: 32px !important;
  }
  .page {
    height: 91vh !important;
    height: 91svh !important;
  }
}